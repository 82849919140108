<template>
<div>
  <b-overlay :show="loadingPage" rounded="lg" opacity="0.7">
    <validation-observer ref="simpleRules">

      <!--BEGIN CARD EDIÇÃO-->
      <div class="p-2 card" v-if="mostrarEdicao">
        <b-row class="align-items-center">
          <b-col class="d-flex">
            <h5 class="question text-center">Questão {{mostraQuestao+1}}</h5>
            <span class="ml-1 text-center default-font-properties qtd-questions"> - {{mostraQuestao+1}} de {{ questionario.questoes.length}} questões </span>
          </b-col>
          <b-col align="right">
            <b-button
              type="submit"
              variant="custom"
              class="mr-1 btn-sm"
              @click.prevent="salvarQuestaoEditada()"
            >
              <span>
                Salvar
              </span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="d-flex mt-1" align="left">
          <b-col lg="3" class="d-flex">
            <div>
              <b-form-radio
                v-model="questionario.usarFeedback"
                name="some-radios"
                :value="true"
                class="title-radio-custom"
              >
                Utilizar feedback
              </b-form-radio>
            </div>
          </b-col>
          <b-col lg="6" class="d-flex">
            <div>
              <b-form-radio
                v-model="questionario.usarFeedback"
                name="some-radios"
                :value="false"
                class="title-radio-custom"
              >
                Não utilizar feedback
              </b-form-radio>
            </div>
          </b-col>
        </b-row>
        <!-- PERCORRE AS QUESTÕES -->
        <div v-for="(questao, index) in questionario.questoes" :key="index">
          <div v-if="index== mostraQuestao">
            <div class="mt-3 ml-1 mb-1">
              <b-row>
                <b-form-checkbox
                  v-model="questao.tipoSelecao.selecaoMultipla"
                  @change="trocaTipoSelecao('selecaoMultipla', index)"
                />
                <small> Seleção múltipla </small>
                <b-form-checkbox
                  v-model="questao.tipoSelecao.multiplaEscolha"
                  class="ml-1"
                  @change="trocaTipoSelecao('multiplaEscolha', index)"
                />
                  <small> Escolha única </small>
              </b-row>
              <b-row v-if="!questao.tipoSelecao.selecaoMultipla && !questao.tipoSelecao.multiplaEscolha">
                <small class="text-danger mt-1"> Selecione uma opção </small>
              </b-row>
              <b-row v-if="questionario.usarFeedback">
                <b-col lg="12" align="right" class="d-flex flex-row-reverse">
                  <small
                    class="cursor-pointer"
                    @click="infoPontuacao()"
                  >
                    Peso total da questão: 36
                    <feather-icon
                      class="info-pontuacao cursor-pointer"
                      icon="InfoIcon"
                      @click="infoPontuacao()"
                    />
                  </small>
                </b-col>
              </b-row>
            </div>
            <validation-provider #default="{ errors }" rules="required">
              <b-input-group class="mt-0">
                <template #append>
                  <b-input-group-text
                    v-if="questionario.questoes.length>1"
                    @click.prevent="removeQuestao(index)"
                    class="cursor-pointer"
                    :disabled="questionario.questoes.length > 1 ? false : true"
                    :style="errors.length > 0 ? 'border: 1px solid red' : null"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="14"
                    />
                  </b-input-group-text>
                </template>
                <b-form-input
                  v-model="questionario.questoes[index].enunciado"
                  placeholder="Digite aqui o enunciado* (máx. 115 caracteres)"
                  autocomplete="off"
                  maxlength="115"
                  class="placeholder-bold"
                  :style="errors.length > 0 ? 'border: 1px solid red' : null"
                />
              </b-input-group>
              <small v-if="errors.length > 0" class="text-danger ml-1">
                Campo Obrigatório
              </small>
            </validation-provider>
            <!-- PERCORRE AS ALTERNATIVAS -->
            <div v-for="(alternativa, indice) in questao.alternativas" :key="indice">
              <!-- MOSTRA CAMPOS REFERENTES AO FEEDBACK -->
              <div v-if="questionario.usarFeedback">
                <validation-provider #default="{ errors }" rules="required">
                  <b-input-group class="mt-1">
                    <template #append>
                      <b-input-group-text
                        @click.prevent="indice >= 2 &&  removeAlternativa(mostraQuestao,indice)"
                        :disabled="indice <= 2 ? true: false"
                        class="cursor-pointer"
                        style="border: none;"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          size="14"
                        />
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="alternativa.opcao"
                      autocomplete="off"
                      class="placeholder-black mr-1 rounded tamanho-input"
                      maxlength="115"
                      :style="errors.length > 0 ? 'border: 1px solid red' : ''"
                    />
                    <b-form-input
                      v-model="alternativa.peso"
                      class="rounded"
                      :style="errors.length > 0 || alternativa.peso == 0 ? 'border: 1px solid red; width:5%;' : 'width:5%;'"
                      type="number"
                      placeholder="Peso"
                      maxlength="20"
                      autocomplete="off"
                      @keyup="somaPontuacaoTotal(index)"
                    />
                  </b-input-group>
                  <small v-if="errors.length>0" class="text-danger ml-1">
                    Campo Obrigatório
                  </small>
                  <b-col align="right">
                    <small v-if="errors.length || alternativa.peso==0"  class="text-danger mr-1">
                      Valor Inválido
                    </small>
                  </b-col>
                </validation-provider>
              </div>
              <!-- MOSTRA CAMPOS PARA QUESTÕES SEM FEEDBACK -->
              <div v-else>
                <validation-provider #default="{ errors }" rules="required">
                  <b-input-group class="mt-1" id="teste">
                    <template #append>
                      <b-input-group-text
                          @click.prevent="indice >= 2 && removeAlternativa(mostraQuestao,indice)"
                          :disabled="indice < 2"
                          class="cursor-pointer"
                          :style="errors.length > 0 ? { 'border': '1px solid red' } : null"
                      >
                          <feather-icon
                              icon="Trash2Icon"
                              size="14"
                          />
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="alternativa.opcao"
                      autocomplete="off"
                      maxlength="115"
                      class="placeholder-black"
                      :style="errors.length > 0 ? 'border: 1px solid red' : null"
                    />
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger ml-1">
                    Campo Obrigatório
                  </small>
                </validation-provider>
              </div>
            </div>
            <small v-if="questionario.usarFeedback && questao.pontuacaoTotal != 36" class="text-danger ml-1"><br>
              Soma total da pontuação é diferente de 36.
            </small><br/>
            <small class="custom-information"> Mínimo de 2 e máximo de 8 alternativas </small>
            <div class="mt-1">
              <b-button
                type="submit"
                variant="custom"
                class="min-button"
                @click.prevent="questao.alternativas.length <= 7 && insereAlternativa(index)"
                :disabled="questao.alternativas.length <= 7 ? false : true"
              >
                +
              </b-button>
              <small class="placeholder-black ml-custom">
                Inserir mais alternativas
              </small>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3 mb-1">
          <button
            class="c-paginator-arrows mr-1"
            @click="voltar()"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="14"
            />
          </button>
          <div
            v-for="number in displayedNumbers"
            :key="number"
          >
            <button
              :class="mostraQuestao+1 == number ? 'c-paginator-arrows-reverse' : 'c-paginator-arrows-none' "
              class="mr-1"
              @click="irParaQuestao(number)"
            >{{ number }}</button>

          </div>
          <button
            class="c-paginator-arrows"
            @click="proximo()"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="14"
            />
          </button>
        </div>
      </div>
      <!--END CARD EDIÇÃO-->

      <!-- BEGIN CARD QUESTÕES -->
      <div class="p-2 card" v-if="!mostrarEdicao">
        <b-row>
          <b-col class="d-flex align-items-center">
            <h5 class="question text-center">Revise as questões cadastradas</h5>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              type="submit"
              variant="outline-primary"
              class="mr-1 btn-sm"
              @click.prevent="editar()"
            >
              <span>
                <feather-icon
                  icon="Edit3Icon"
                  size="14"
                />
                Editar
              </span>
            </b-button>
            <b-button
              type="submit"
              variant="outline-primary"
              class="btn-sm"
              @click.prevent="inserirQuestao()"
            >
              <span>
                <feather-icon
                  icon="PlusSquareIcon"
                  size="14"
                />
                Inserir questão
              </span>
            </b-button>
          </b-col>
        </b-row>
        <b-alert
          v-height-fade
          show
          dismissible
          fade
          class="mt-1"
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              icon="AlertCircleIcon"
              size="14"
              class="mr-1"
            />
            <span>Aqui você pode revisar as questões cadatradas no questionário e editá-las.</span>
          </div>
        </b-alert>
        <div class="mt-1">
          <app-collapse>
            <div class="border-collapse">
              <b-row
                :aria-expanded="collapseVisible.visibleOptionsQuestion ? 'true' : 'false'"
                aria-controls="collapse-options-question"
                class="mt-1 mb-1 collapsed"
                @click="collapseVisible.visibleOptionsQuestion = !collapseVisible.visibleOptionsQuestion"
              >
                <b-col lg="10" class="ml-1">
                  <h5
                    v-if="mostraQuestao>=0"
                    class="title-question"
                  >
                    <strong> {{questionario.questoes[mostraQuestao].enunciado}} </strong>
                  </h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <b-collapse
                    id="collapse-options-question"
                    v-model="collapseVisible.visibleOptionsQuestion"
                    class="default-font-properties options-question"
                  >
                    <b-card>
                      <div
                        v-for="(alternativa, x) in questionario.questoes[mostraQuestao].alternativas"
                        :key="x"
                      >
                        <span> {{labelAlternativa[x+1]+')'+' '+alternativa.opcao}} </span>
                      </div>
                    </b-card>
                  </b-collapse>
                </b-col>
              </b-row>
            </div>
          </app-collapse>
          <b-row>
            <b-col
              lg="4"
              class="d-flex align-items-center"
            >
              <span
                class="qtd-questions default-font-properties mt-2"
              >
                Mostrando {{ mostraQuestao+1 }} de {{ questionario.questoes.length }} resultados
              </span>
            </b-col>
            <b-col lg="8">
              <div class="d-flex justify-content-end mt-3 mb-1">
                <button
                  class="c-paginator-arrows mr-1"
                  @click="voltar()"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="14"
                  />
                </button>
                <div
                  v-for="number in displayedNumbers"
                  :key="number"
                >
                  <button
                    :class="mostraQuestao+1 == number ? 'c-paginator-arrows-reverse' : 'c-paginator-arrows-none' "
                    class="mr-1"
                    @click="irParaQuestao(number)"
                  >
                    {{ number }}
                  </button>
                </div>
                <button
                  class="c-paginator-arrows"
                  @click="proximo()"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="14"
                  />
                </button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- END CARD QUESTÕES -->

      <!-- BEGIN CARD FEEDBACKS -->
      <div class="p-2 card" v-if="questionario.usarFeedback">
        <b-row>
          <h1 class="question">Intervalo de feedbacks*</h1>
        </b-row>
        <b-row v-if="mostrarAlerta && questionario.usarFeedback">
          <div class="custom-space-alert mt-1 mb-1">
            <b-alert
              :show="mostrarAlerta"
              variant="warning"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="AlertTriangleIcon"
                />
                <span class="ml-25">
                  <b>Pontuação de Feedback.</b>
                  Este formulário possui feedbacks com pontuação, por favor verifique se os intervalos estão preenchidos.
                </span>
              </div>
            </b-alert>
          </div>
        </b-row>
        <b-row >
          <b-col lg="4">
            <b-row>
              <b-col lg="3" class="mt-1 col-img-cora">
                <b-img :src="require('@/assets/images/cora/Cora.png')" class="img-cora-properties"/>
              </b-col>
              <b-col class="mt-4 col-msg-cora">
                <b-row class="row-msg-cora">
                  <span class="elemento"> Oi! <strong> Eu sou a Cora! </strong> </span>
                </b-row>
                <b-row>
                  <span class="elemento mt-1 mb-2">
                    <div class="custom-margin-top-cora" />
                    <div class="description-feedback-area display-colum-for-mobile">
                      <span> Estou aqui para <strong> te ajudar a calcular a pontuação do feedback</strong> do seu questionário! </span>
                      <br />
                      <br />
                      <span>
                        Eu vi que você cadastrou
                        {{this.questionario.questoes.length > 1 ? this.questionario.questoes.length + ' questões' : this.questionario.questoes.length + ' questão'}}
                        e, então, a pontuação mínima possível nesse questionário é de {{this.pontuacao.minimo}}, e o máximo de pontos é {{this.pontuacao.maximo}}.
                      </span>
                      <br />
                      <br />
                      <span>
                        Se você precisar de ajuda, aqui vai uma sugestão de quais intervalos de pontuação você pode usar para cadastrar seus feedbacks:
                      </span>
                    </div>
                    <div class="d-flex justify-content-center">

                      <b-row class="mt-1 mb-1">
                        <b-col align="center">
                          <b-row>
                            <span v-if="this.pontuacao.intervaloA > 0">
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="14"
                                color="#2772C0"
                              />
                              {{this.pontuacao.intervaloA}} a {{this.pontuacao.intervaloB}}
                            </span>
                          </b-row>
                          <b-row>
                            <span v-if="this.pontuacao.intervaloC > 0">
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="14"
                                color="#2772C0"
                              />
                              {{this.pontuacao.intervaloC}} a {{this.pontuacao.intervaloD}}
                            </span>
                          </b-row>
                          <b-row>
                            <span v-if="this.pontuacao.intervaloE > 0">
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="14"
                                color="#2772C0"
                              />
                              {{this.pontuacao.intervaloE}} a {{this.pontuacao.intervaloF}}
                            </span>
                          </b-row>
                          <b-row>
                            <span v-if="this.pontuacao.intervaloG > 0">
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="14"
                                color="#2772C0"
                              />
                              {{this.pontuacao.intervaloG}} a {{this.pontuacao.intervaloH}}
                            </span>
                          </b-row>
                        </b-col>
                      </b-row>

                    </div>
                  </span>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <div v-for="(feedback,i) in questionario.feedbacks" :key="i">
              <div :id="'intervalo'+i" class="mb-2">
                <b-row class="display-cutom-mobile">
                  <b-col>
                    <b-form-input
                      v-model="feedback.valorInicial"
                      id="input-intervalo-inicial"
                      type="number"
                      min="1"
                      max="10"
                      autocomplete="off"
                      :disabled="true"
                    />
                  </b-col>
                  <span> A </span>
                  <b-col>
                    <b-form-input
                      v-if="i === questionario.feedbacks.length-1"
                      v-model="pontuacao.maximo"
                      id="input-intervalo-final"
                      type="number"
                      autocomplete="off"
                      @keyup="alteraValorFeedback(i)"
                      :disabled="true"
                    />
                    <b-form-input
                      v-else
                      v-model="feedback.valorFinal"
                      id="input-intervalo-final"
                      type="number"
                      autocomplete="off"
                      @keyup="alteraValorFeedback(i)"
                      :disabled="false"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col>
                    <app-collapse>
                      <b-col class="border-collapse">
                        <b-row
                          :class="collapseVisible.visibleOptionsQuestion ? null : 'collapsed'"
                          :aria-expanded="collapseVisible.visibleOptionsQuestion ? 'true' : 'false'"
                          :aria-controls="'collapse-interval'+i"

                          class="mt-1"
                        >
                          <b-col>
                            <h5 class="title-question"> Feedback </h5>
                          </b-col>
                          <b-col align="right">
                            <span>
                              <div
                                v-if="i == questionario.feedbacks.length-1 && questionario.feedbacks.length >2"
                                @click.prevent="removeFeedback()"
                                class="cursor-pointer"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  size="20"
                                />
                              </div>
                            </span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="12">
                            <b-collapse
                              :id="'collapse-interval'+i"
                              v-model="collapseVisible.visibleOptionsQuestion"
                            >
                              <b-card>
                                <b-form-textarea
                                  v-model="feedback.mensagem"
                                  id="input-descricao"
                                  rows="3"
                                  placeholder="Resposta, escreva aqui a resposta da pergunta. Escreva com uma linguagem clara e objetiva, para não deixar outras duvidas."
                                  autocomplete="off"
                                  maxlength="300"
                                  class="border-0 shadow-none resize-none"
                                  @keyup="verificaFeedbackPreenchido()"
                                />
                              </b-card>
                            </b-collapse>
                          </b-col>
                        </b-row>
                      </b-col>
                    </app-collapse>
                  </b-col>
                </b-row>
              </div>
            </div>
             <b-row>
                  <b-col>
                    <b-button
                      type="submit"
                      variant="custom"
                      class="width-custom"
                      @click.prevent="inserirFeedback()"
                      :disabled="questionario.feedbacks.length >=4 ? true : false"
                    >
                      <span>
                        +
                      </span>
                    </b-button>
                    <span> Inserir mais </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-2">
            <div class="separator"></div>
          </b-col>
        </b-row>
      </div>
      <!-- END CARD FEEDBACKS -->

      <!-- BOTÕES -->
      <div class="p-1 card">
        <b-row>
          <b-col class="d-flex justify-content-start margin-custom-mobile">
            <b-button
              type="submit"
              variant="custom"
              class="ml-1"
              @click.prevent="voltarParaCriar()"
            >
              <span>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="15"
                />
               <span v-if="mostrarTextoBotao"  class="space-beetwen-button-text">Voltar</span>
              </span>
            </b-button>
            <b-button
              type="submit"
              variant="outline-primary"
              class="ml-1"
              @click.prevent="exportarQuestionario()"
            >
              <span>
                <feather-icon
                  icon="DownloadIcon"
                  size="15"
                />
                <span v-if="mostrarTextoBotao" class="space-beetwen-button-text"> Exportar </span>
              </span>
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-for-mobile">
            <b-button
              type="submit"
              variant="outline-primary"
              class="mr-1"
              @click.prevent="cancelar()"
            >
              <span>
                Cancelar
              </span>
            </b-button>
            <b-button
              type="submit"
              variant="custom"
              class="mr-1"
              @click.prevent="salvarRascunho()"
            >
              <span>
                Rascunho
              </span>
            </b-button>
            <b-button
              type="submit"
              variant="custom"
              class="mr-1"
              @click.prevent="salvar()"
            >
              <span>
                Salvar e habilitar
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="mt-1"><span class="p-1"> </span>
      </div>
    </validation-observer>
    </validation-observer>
  </b-overlay>
</div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
  import { required } from "@validations";
  import { heightFade } from '@core/directives/animations';
  import Ripple from 'vue-ripple-directive';
  import {
    modalGenericModel
  } from '@/libs/sweetalerts';
  import {
    BFormTextarea,
    BCardText,
    BCardBody,
    BCard,
    BCardHeader,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCollapse,
    BFormRadio,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BImg,
    BAlert,
    BFormCheckbox
  } from "bootstrap-vue";

  export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    required,
    BFormTextarea,
    BCardText,
    BCardBody,
    BCard,
    BCardHeader,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCollapse,
    BFormRadio,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BImg,
    BAlert,
    BFormCheckbox
  },

  directives: {
    heightFade,
    Ripple,
  },

  data() {
    return {
      loadingPage: true,
      mostrarEdicao: false,
      mostrarTextoBotao: true,
      totalNumbers: 0,
      currentIndex: 1,
      mostraQuestao: 0,
      mostrarAlerta: false,
      questionario: {
        descricao: '',
        usarFeedback: null,
        qtdQuestoes: null,
        questoes: [{
          qtdAlternativas: 2,
          pontuacaoTotal: 0,
          enunciado: null,
          tipoSelecao: {
            selecaoMultipla: false,
            multiplaEscolha: false
          },
          alternativas: [
            {
              opcao: null,
              peso: null
            },
            {
              opcao: null,
              peso: null
            }
          ]
        }],
        feedbacks: [],
      },
      pontuacao: {
        minimo: 0,
        maximo: 0,
        intervaloA: 0,
        intervaloB: 0,
        intervaloC: 0,
        intervaloD: 0,
        intervaloE: 0,
        intervaloF: 0,
        intervaloG: 0,
        intervaloH: 0,
      },
      labelAlternativa:{
        1: 'a',
        2: 'b',
        3: 'c',
        4: 'd',
        5: 'e',
        6: 'f',
        7: 'g',
        8: 'h',
      },
      collapseVisible: {
        visibleOptionsQuestion: true,
        1: true,
        2: false,
        3: false,
        4: false,
      }
    };
  },

  computed: {
    displayedNumbers(){
      this.totalNumbers = this.questionario.questoes.length;
      const numbers = [];
      const numToShow = Math.min(7, this.totalNumbers);
      for (let i = 0; i < numToShow; i++) {
        let number = this.currentIndex + i;
        if (number > this.totalNumbers) {
          number -= this.totalNumbers;
        }
        numbers.push(number);
      }
      return numbers;
    }
  },

  async mounted(){
    await this.carregaQuestionario();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.loadingPage = false;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  props: {
    questionarioData: {
      type: Object,
      required: true
    },
  },

  methods: {

    handleResize() {
      this.mostrarTextoBotao = window.innerWidth >= 760;
    },

    voltarParaCriar(){
      this.$router.push({ name: 'inserir-questionario', params: { questionarioData: this.questionario }});
    },

    async carregaQuestionario(){
      if(!this.questionarioData){
        this.$router.push({ name: 'inserir-questionario'});
      }else{
        this.questionario.nome         = this.questionarioData.descricao;
        this.questionario.descricao    = this.questionarioData.descricao_tecnica;
        this.questionario.usarFeedback = this.questionarioData.usar_feedback;
        this.questionario.questoes     = this.questionarioData.questoes;
      }
    },

    inserirQuestao(){
      if(this.questionario.questoes.length<50){
        let novaQuestao =  {
          enunciado: '',
          pontuacaoTotal: 0,
          qtdAlternativas: 2,
          tipoSelecao: {
            selecaoMultipla: null,
            multiplaEscolha: null
          },
          alternativas: [
            {
              opcao: '',
              peso: 0
            },
            {
              opcao: '',
              peso: 0
            }
          ]
        };
        this.questionario.questoes.push(novaQuestao);
      }
      this.mostraQuestao = this.questionario.questoes.length-1;
      this.mostrarEdicao = true;
    },

    trocaTipoSelecao(tipoSelecao, index){
      if(tipoSelecao === 'selecaoMultipla'){
        this.questionario.questoes[index].tipoSelecao.selecaoMultipla = true;
        this.questionario.questoes[index].tipoSelecao.multiplaEscolha = false;
      }else if(tipoSelecao === 'multiplaEscolha'){
        this.questionario.questoes[index].tipoSelecao.selecaoMultipla = false;
        this.questionario.questoes[index].tipoSelecao.multiplaEscolha = true;
      }else{
        this.questionario.questoes[index].tipoSelecao.selecaoMultipla = null;
        this.questionario.questoes[index].tipoSelecao.multiplaEscolha = null;
      }
    },

    removeAlternativa(questao, alternativa){
      if(this.questionario.questoes[questao].alternativas.length > 2){
        this.questionario.questoes[questao].alternativas.splice(alternativa, 1);
        this.somaPontuacaoTotal(questao);
      }
      this.zeraPontuacaoMinimaMaxima();
      this.atualizaMinimoMaximo();
    },

    irParaQuestao(indice){
      this.mostraQuestao = indice-1;
    },

    voltar(){
      if(this.mostraQuestao > 0){
        if(this.currentIndex>1){
          this.currentIndex--;
        }
        this.mostraQuestao--;
      }else{
        this.currentIndex = this.questionario.questoes.length;
        this.mostraQuestao = this.currentIndex-1;
      }
    },

    proximo(){
      if(this.mostraQuestao+1 < this.questionario.questoes.length){
        this.currentIndex++;
        this.mostraQuestao++;
      }else{
        this.currentIndex = 1;
        this.mostraQuestao = 0;
      }
    },

    salvarQuestaoEditada(){
      this.loadingPage = true;
      let vazio = false;
      for(let questao of this.questionario.questoes){
        for(let alternativa of questao.alternativas){
          if(
            !questao.enunciado ||
            !alternativa.opcao
          ){
            vazio = true;
          }
        }
        if(
          questao.tipoSelecao.selecaoMultipla == null ||
          questao.tipoSelecao.multiplaEscolha == null
        ){
          vazio = true;
        }
      }
      if(this.questionario.usarFeedback){
        if(this.verificaPontuacao()  || this.verificaPeso()){
          vazio = true;
        }
      }

      this.disparaModalDeAcordoComVazio(vazio)
    },

    disparaModalDeAcordoComVazio(vazio){
      if(vazio){
        modalGenericModel(
          'Confira o preenchimento correto dos campos',
          '',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Ok'
        );
        this.loadingPage = false;
      }else{
        modalGenericModel(
          'Sucesso!',
          'Sua atualização no questionário foi salva com sucesso!',
          require('@/assets/custom-icons/cora-icons/success.png'),
          'Ok'
        )
        this.mostrarEdicao = false;
        this.loadingPage = false;
      }
    },

    verificaPontuacao(){
      let soma = 0;
      for(let questao of this.questionario.questoes){
        for(let alternativa of questao.alternativas){
          soma = soma + parseInt(alternativa.peso);
        }
        if(soma != 36){
          return true;
        }
        soma = parseInt(0);
        this.zeraPontuacaoMinimaMaxima();
        this.atualizaMinimoMaximo();
      }
      return false;
    },

    verificaPeso(){
      for(let questao of this.questionario.questoes){
        for(let alternativa of questao.alternativas){
          if(!alternativa.peso || alternativa.peso == 0){
            return true;
          }
        }
      }
      return false;
    },

    atualizaMinimoMaximo(){
      let minimo = 0;
      let maximo = 0;
      for(let i=0; i < this.questionario.questoes.length; i++){

        minimo = minimo + this.procuraMenorValor(i);
        maximo = maximo + this.procuraMaiorValor(i);
      }
      this.pontuacao.minimo = minimo;
      this.pontuacao.maximo = maximo;
      if(this.questionario.usarFeedback){
        this.questionario.feedbacks[0].valorInicial = minimo;
      }
      this.preencheIntervalos();
    },

    preencheIntervalos(){
      let diferenca = this.pontuacao.maximo - this.pontuacao.minimo;
      if(this.questionario.feedbacks.length <= 2){
        this.pontuacao.intervaloA = parseInt(this.pontuacao.minimo);
        this.pontuacao.intervaloB = parseInt(this.pontuacao.minimo) + parseInt(Math.trunc(diferenca/2));
        this.pontuacao.intervaloC = parseInt(this.pontuacao.intervaloB) + parseInt(1);
        this.pontuacao.intervaloD = parseInt(this.pontuacao.maximo);
      }
      if(this.questionario.feedbacks.length === 3){
        this.pontuacao.intervaloA = parseInt(this.pontuacao.minimo);
        this.pontuacao.intervaloB = parseInt(this.pontuacao.minimo) + parseInt(Math.trunc(diferenca/3));
        this.pontuacao.intervaloC = parseInt(this.pontuacao.intervaloB) + parseInt(1);
        this.pontuacao.intervaloD = parseInt(this.pontuacao.intervaloC) + parseInt(Math.trunc(diferenca/3));
        this.pontuacao.intervaloE = parseInt(this.pontuacao.intervaloD) + parseInt(1);
        this.pontuacao.intervaloF = parseInt(this.pontuacao.maximo);
      }
      if(this.questionario.feedbacks.length === 4){
        this.pontuacao.intervaloA = parseInt(this.pontuacao.minimo);
        this.pontuacao.intervaloB = parseInt(this.pontuacao.minimo) + parseInt(Math.trunc(diferenca/4));
        this.pontuacao.intervaloC = parseInt(this.pontuacao.intervaloB) + parseInt(1);
        this.pontuacao.intervaloD = parseInt(this.pontuacao.intervaloB) + parseInt(Math.trunc(diferenca/4));
        this.pontuacao.intervaloE = parseInt(this.pontuacao.intervaloD) + parseInt(1);
        this.pontuacao.intervaloF = parseInt(this.pontuacao.intervaloD) + parseInt(Math.trunc(diferenca/4));
        this.pontuacao.intervaloG = parseInt(this.pontuacao.intervaloF) + parseInt(1)
        this.pontuacao.intervaloH = parseInt(this.pontuacao.maximo);
      }
    },

    procuraMenorValor(indice){
      let minimo = parseInt(37);
      for(let alternativa of this.questionario.questoes[indice].alternativas){
        if(alternativa.peso != null && alternativa.peso !=0){
          if(alternativa.peso && alternativa.peso < minimo){
            minimo = parseInt(alternativa.peso);
          }
        }
      }
      if(minimo === 37){
        return 0;
      }else{
        return minimo;
      }
    },

     procuraMaiorValor(indice){
      let maximo = 0;
      if(this.questionario.questoes[indice].tipoSelecao.selecaoMultipla){
        maximo = 36;
      }else{
        for(let alternativa of this.questionario.questoes[indice].alternativas){
          if(alternativa.peso != null && alternativa.peso !=0){
            if(alternativa.peso && alternativa.peso > maximo){
              maximo = parseInt(alternativa.peso);
            }
          }
        }
      }
      return maximo;
    },

    zeraPontuacaoMinimaMaxima(){
      this.pontuacao.minimo = 0;
      this.pontuacao.maximo = 0;
      this.pontuacao.intervaloA = 0;
      this.pontuacao.intervaloB = 0;
      this.pontuacao.intervaloC = 0;
      this.pontuacao.intervaloD = 0;
      this.pontuacao.intervaloE = 0;
      this.pontuacao.intervaloF = 0;
      this.pontuacao.intervaloG = 0;
      this.pontuacao.intervaloH = 0;
    },

    somaPontuacaoTotal(n){
      let total = 0;
      for(let alternativa of this.questionario.questoes[n].alternativas){
        if(alternativa.peso != null){
          total = parseInt(total) + parseInt(alternativa.peso);
        }
      };
      this.questionario.questoes[n].pontuacaoTotal = total;
    },

    inserirFeedback(){
      let qtdFeedback = this.questionario.feedbacks.length;
      if(qtdFeedback<4){
        let feedback = {
            valorInicial: 0,
            valorFinal: 0,
            mensagem: ''
        };
        this.questionario.feedbacks.push(feedback);
      }
      this.zeraPontuacaoMinimaMaxima();
      this.atualizaMinimoMaximo();
      this.mostrarAlerta = true;
      this.collapseVisible[this.questionario.feedbacks.length] = true;
    },

     insereAlternativa(i){
      if(this.questionario.questoes[i].alternativas.length<=7){
        let opcao = {opcao:null};
        this.questionario.questoes[i].alternativas.push(opcao);
        this.questionario.questoes[i].qtdAlternativas++;
        this.atualizaMinimoMaximo();
      }
    },

    editar(){
      this.mostrarEdicao = !this.mostrarEdicao;
    },

    alteraValorFeedback(i){
      if(this.questionario.feedbacks[i].valorFinal){
        let valorInicial = parseInt(this.questionario.feedbacks[i].valorInicial);
        let valorFinal   = parseInt(this.questionario.feedbacks[i].valorFinal);
        if(valorFinal<valorInicial || valorFinal > this.pontuacao.maximo){
          this.mostrarAlerta = true;
        }else{
          this.questionario.feedbacks[i+1].valorInicial = valorFinal+1;
          this.verificaFeedbackPreenchido();
        }
      }
    },

    verificaFeedbackPreenchido(){
      let mostrar = false;
      if(this.questionario.usarFeedback){
        let x = this.questionario.feedbacks.length;
        this.questionario.feedbacks[x-1].valorFinal = this.pontuacao.maximo;
        for(let i=0; i<x; i++){
          if(this.questionario.feedbacks[i].valorFinal < this.questionario.feedbacks[i].valorInicial){
            mostrar = true;
          }
          if(!this.questionario.feedbacks[i].valorInicial ||
             !this.questionario.feedbacks[i].valorFinal ||
             !this.questionario.feedbacks[i].mensagem
          ){
              mostrar = true;
          }
          this.mostrarAlerta = mostrar;
        }
      }
    },

    removeQuestao(questao){
      if(this.questionario.questoes.length>1){
        this.questionario.questoes[questao].enunciado = '';
        this.questionario.questoes[questao].pontuacaoTotal = 0;
        this.questionario.questoes[questao].feedback = [{valorInicial: null,valorFinal: null,mensagem: null}];
        this.questionario.questoes[questao].tipoSelecao = {selecaoMultipla: false,multiplaEscolha: false};
        this.questionario.questoes[questao].alternativas = [{opcao: '',peso: null},{opcao: '',peso: null}];
        this.questionario.questoes.splice(questao,1);
        this.mostraQuestao--;
        this.currentIndex = this.mostraQuestao+1;
      }
      if(questao==0){
        this.proximo();
      }
      this.zeraPontuacaoMinimaMaxima();
      this.atualizaMinimoMaximo();
    },

    removeFeedback(){
      if(this.questionario.feedbacks.length > 2){
        this.questionario.feedbacks.pop();
      }
      this.zeraPontuacaoMinimaMaxima();
      this.atualizaMinimoMaximo();
      this.verificaFeedbackPreenchido();
    },

    habilitarFeedback(){
      this.inserirFeedback();
      this.inserirFeedback();
      this.questionario.usarFeedback = true;
      this.mostrarTelaFeedback = true;
      this.habilitaSalvar = true;
      this.verificaFeedbackPreenchido();
    },

    exportarQuestionario(){
      modalGenericModel(
        'Exportar Questionário',
        'Ao exportar o questionário você poderá revisar todas as informações que cadastrou.' +
        ' Você pode deixar seu questionário salvo como rascunho enquanto isso!',
        require('@/assets/custom-icons/cora-icons/download.png'),
        'Entendi'
      ).then((result) => {
        if (result.isConfirmed) {
          this.baixarQuestionario();
        }else{
          return ;
        }
      });
    },

    async baixarQuestionario(){
      this.loadingPage = true;
      let questionario = this.formataDadosQuestionario();
      await this.$http.post(this.$api.questionario.exportarRespostasPdf, questionario, {responseType: "blob"})
        .then(response => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(response.data);
          link.download = questionario.descricao+'.pdf';
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          modalGenericModel(
            error.response.data.error,
            '',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Entendi'
          );
        });
      this.loadingPage = false;
    },

    formataDadosQuestionario(){
      this.questionario.qtdQuestoes = this.questionario.questoes.length;

      for (let value of this.questionario.questoes) {
        value.pontuacaoTotal = 36;
      }

      return {
        descricao:         this.questionario.nome,
        descricao_tecnica: this.questionario.descricao,
        usar_feedback:     this.questionario.usarFeedback ,
        qtdQuestoes:       this.questionario.qtdQuestoes,
        questoes:          this.questionario.questoes,
        feedback:          this.questionario.usarFeedback ? this.questionario.feedbacks : []
      };
    },

    async inserirComoRascunho(){
      this.loadingPage = true;
      let questionario = this.formataDadosQuestionario();
      await this.$http.post(this.$api.questionario.inserirRascunho, questionario)
        .then(() => {
          modalGenericModel(
            'Sucesso!',
            'Novo questionário salvo como rascunho!',
            require('@/assets/custom-icons/cora-icons/success.png'),
            'Ok'
          ).then(() => {
            this.$router.push({ name: 'listagem-questionarios' })
          })
        })
        .catch((error) => {
          modalGenericModel(
            error.response.data.error,
            '',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Entendi'
          );
        });
      this.loadingPage = false;
    },

    desabilitarFeedback(){
      this.questionario.feedback = [];
      this.questionario.usarFeedback = false;
      this.mostrarTelaFeedback = false;
      this.mostrarAlerta = false;
    },

    cancelar(){
      modalGenericModel(
        'Cancelar',
        'Você tem certeza que deseja cancelar e perder as alterações cadastradas?',
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Sim, cancelar',
        'Não, continuar editando'
      ).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'listagem-questionarios' });
          }else{
            return ;
          }
        });
    },

     salvarRascunho(){
      modalGenericModel(
        'Salvar como Rascunho',
        'Salvar o questionário como rascunho fará com que ele fique disponível' +
        ' na lista de questionários, mas ele não poderá ser utilizado' +
        ' até ser finalizado e habilitado para uso.',
        require('@/assets/custom-icons/cora-icons/edit.png'),
        'Entendi, Continuar',
        'Cancelar'
      ).then((result) => {
        if (result.isConfirmed) {
          this.inserirComoRascunho();
        }else{
          return ;
        }
      });
    },

    salvar(){
      modalGenericModel(
        'Salvar e Habilitar',
        'Ao salvar e habilitar para uso, o questionário ficará na lista de questionários criados ativos e você poderá criar uma aplicação a partir dele.',
        require('@/assets/custom-icons/cora-icons/success.png'),
        'Sim, prosseguir',
        'Cancelar'
      ).then((result) => {
        if (result.isConfirmed) {
          this.inserirEUsar();
        }
      });
    },

    async inserirEUsar(){
      let valorValido = false;

      for (let value of this.questionario.feedbacks) {
        if(
          value.valorInicial < this.pontuacao.minimo ||
          value.valorInicial > this.pontuacao.maximo ||
          value.valorFinal < this.pontuacao.minimo ||
          value.valorFinal > this.pontuacao.maximo
        ){
          valorValido = true;
        }
      }

      if(valorValido || this.mostrarAlerta){
        modalGenericModel(
          'Atenção',
          'Confira o preenchimento correto da pontuação e dos textos de feedback antes de finalizar',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Entendi'
        )
        this.pontuacao.valorValido = true;
      }else{
        this.loadingPage = true;
        let questionario = this.formataDadosQuestionario();
        await this.$http.post(this.$api.questionario.inserir, questionario)
          .then(() => {
            modalGenericModel(
              'Sucesso!',
              'Novo questionário criado com sucesso!',
              require('@/assets/custom-icons/cora-icons/success.png'),
              'Sim, prosseguir'
            ).then(() => {
              this.$router.push({ name: 'listagem-questionarios' })
            })
          })
          .catch((error) => {
            modalGenericModel(
              error.response.data.error,
              '',
              require('@/assets/custom-icons/cora-icons/warning.png'),
              'Entendi'
            )
          });
      }
      this.formataDadosQuestionario();
      this.loadingPage = false;
    },

    infoPontuacao(){
      modalGenericModel(
        'Incluir pontuação',
        'É necessário incluir a pontuação em todas as alternativas' +
        ' cadastradas antes de continuar. A pontuação de todas as' +
        ' alternativas somadas deve ser igual a 36',
        require('@/assets/custom-icons/info-gray.svg'),
        'Entendi',
        null,
        true
      );
    },

    disparaModalDeAcordoComNovoValor(novoValor){
      if(novoValor){
        modalGenericModel(
          'Utilizar feedbacks?',
          'Você criou um questionário “sem feedback” e deseja alterar para “com feedback”,' +
          ' portanto, será necessário incluir a pontuação (peso) em cada uma das alternativas.' +
          ' Deseja prosseguir com a edição?',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Sim, prosseguir',
          'Cancelar'
        ).then((result) => {
          if (result.isConfirmed) {
            this.habilitarFeedback();
          }else{
            this.mostrarPrimeiroWarning = false;
            this.desabilitarFeedback();
          }
        });
      }else{
        modalGenericModel(
          'Remover feedbacks?',
          'Você criou um questionário “com feedback” e deseja alterar para “sem feedback”,'+
          ' ao remover o uso de feedbacks do questionário, as pontuações cadastradas serão perdidas.'+
          ' Deseja prosseguir com a edição?',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Sim, prosseguir',
          'Cancelar'
        ).then((result) => {
          if (result.isConfirmed) {
            this.desabilitarFeedback();
          }else{
            this.mostrarPrimeiroWarning = false;
            this.habilitarFeedback();
          }
        });
      }
    }
  },

  watch: {
    'questionario.usarFeedback': function (novoValor) {
      if(!this.mostrarPrimeiroWarning){
        if(novoValor){
          this.habilitarFeedback();
        }else{
          this.desabilitarFeedback();
        }
        this.mostrarPrimeiroWarning = true;
      }else{
        this.disparaModalDeAcordoComNovoValor(novoValor)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .default-font-properties{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
    letter-spacing: 0px;
  }
  .qtd-questions{
    color: #B9B9C3;
  }
  .question{
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: 0px;
  }
  .title-question{
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
  }
  .options-question{
    color: #6E6B7B;
  }
  .border-collapse{
    border: 1px solid #D8D6DE;
    border-radius: 5px;
    box-shadow: 0px 4px 24px 0px #0000000F;
  }
  .ml-custom{
    margin-left: 0.438rem;
    color: #7030A0;
  }
  .description-feedback-area{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0px;
     @media (max-width: 990px) {
      display: flex;
      justify-content: center;
      margin: 0 10%;
    }
  }
  .tamanho-input{
     @media (max-width: 900px) {
      width:55%
    }
    width:80%
  }
  .resize-none{
    resize: none;
  }
  .width-custom{
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    text-align: center;
    margin-right: 0.325rem;
  }
  .title-radio-custom{
    font-size: 0.938rem !important;
  }
  .info-pontuacao{
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 0.275rem;
    margin-bottom: 0.150rem;
  }
  .placeholder-bold{
    font-weight: 600 !important;
  }
  .min-button{
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    margin-top: 0!important;
    text-align: center;
  }
  .separator{
    width: 100%;
    border-bottom: 1px solid #D8D6DE;
  }
  .custom-space-alert{
    width: 100%;
    margin: 0 auto;
  }
  .c-paginator-arrows{
    border: none;
    color: #FFFFFF;
    text-decoration: none;
    width: 28px;
    height: 28px;
    background: #7030A0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-paginator-arrows-reverse{
    text-decoration: none;
    width: 32px;
    height: 32px;
    border: 2px solid #7030A0;
    border-radius: 50%;
    background-color: transparent;
    color: #7030A0;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-paginator-arrows-none{
    text-decoration: none;
    width: 28px;
    height: 28px;
    border: 2px solid transparent;
    border-radius: 50%;
    background-color: transparent;
    color: #7030A0;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-cora-properties{
    width: 6.625rem;
  }
  .elemento{
    padding: 10px 20px;
    font-size: 1.125rem;
    background-color: #E5EEF7;
    border-radius: 10px;
    line-height: 1.25rem;
    letter-spacing: 0px;
  }
  .col-img-cora{
    text-align: right;
    @media(max-width: 1500px){
      margin-right: 2rem;
    }
    @media (max-width: 990px) {
      text-align: center;
    }
  }
  .col-msg-cora{
    text-align: left;
    align-items: left;
    @media (max-width: 990px) {
      text-align: center;
    }
  }
  .row-msg-cora{
    @media (max-width: 990px) {
      display: flex;
      justify-content: center;
    }
  }
  .custom-margin-top-cora{
    margin-top: 1.5rem;
  }
  .display-colum-for-mobile{
    @media (max-width: 990px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .margin-custom-mobile{
    @media (max-width: 596px) {
      margin-bottom: 1rem;
    }
  }
  .display-cutom-mobile{
    width: 50%;
    align-items: center;
    @media (max-width: 990px) {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }
  .space-beetwen-button-text{
    margin-left: 8px;
  }
  .justify-content-for-mobile{
    justify-content: end;
    @media (max-width: 596px) {
      justify-content: start;
      margin-left: 1rem;
    }
  }
</style>
